import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import News from 'components/news-reviews/news'
import Reviews from 'components/news-reviews/reviews'
import PageHeader from 'components/shared/pageHeader'
import { graphql } from 'gatsby'
import React from 'react'

const NewsReviews = ({ data }) => {
  const reviews = data.reviews.nodes
  const news = data.news.nodes
  const meta = {
    title: 'News & Reviews | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <MaxWidth $wooden>
        <News data={news} />
        <Reviews data={reviews} />
      </MaxWidth>
    </Layout>
  )
}

export default NewsReviews

export const NewsReviewsQuery = graphql`
  query {
    reviews: allReviewsJson {
      nodes {
        review
        reviewer
      }
    }
    news: allNewsJson {
      nodes {
        site
        text
        link
      }
    }
  }
`
