import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const ReviewsStyles = styled.section`
  width: 100%;
  position: relative;
  z-index: 2;
  .review {
    width: 90%;
    max-width: 650px;
    margin: 2rem auto;
    text-align: center;
    strong {
      color: var(--mainYellow);
      font-weight: 400;
      font-style: italic;
      font-size: 1.2rem;
      @media ${media.md} {
        font-size: 1.5rem;
      }
    }
    p {
      margin-top: 1rem;
      font-size: 1.5rem;
    }
  }
`
const Reviews = ({ data }) => (
  <ReviewsStyles>
    <section>
      {data.map((review, i) => (
        <div className="review">
          <strong>{`"${review.review}"`}</strong>
          <p>{`${review.reviewer}`}</p>
        </div>
      ))}
    </section>
  </ReviewsStyles>
)

export default Reviews
