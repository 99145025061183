import React from 'react'
import styled from 'styled-components'

const IntroStyle = styled.section`
  width: 100%;
  padding: 3rem 0 0 0;
  text-align: center;
  position: relative;
  z-index: 2;
  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
  }
  .wrapper {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    div {
      padding: 1.5rem;
      strong {
        font-size: 1.5rem;
      }
      p {
        margin-top: 0.35rem;
      }
      button {
        margin-top: 1rem;
      }
    }
  }
`

const Intro = ({ data }) => (
  <IntroStyle>
    <h1>News &amp; Reviews</h1>
    <div className="wrapper">
      {data.map((single, i) => (
        <div key={i}>
          <strong>{single.site}</strong>
          <p>{single.text}</p>
          <a href={single.link} target="_blank" rel="noopener noreferrer">
            <button type="button" className="btn">
              <span>Read Article</span>
            </button>
          </a>
        </div>
      ))}
    </div>
  </IntroStyle>
)

export default Intro
